<template>
  <div>
    <el-dialog :title="text + '教学班级'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">

          <el-col :span="24">
            <el-form-item label="班级类型"
                          prop="grade_id">
              <el-input style="width:100%"
                        readonly
                        v-model="leixing" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="届"
                          prop="graduation_id">
              <el-select v-model="form.graduation_id"
                         style="width:100%"
                         filterable
                         :disabled="text!='新增'"
                         placeholder="请选择">
                <el-option v-for="item in jieList"
                           :key="item.graduated_id"
                           :label="item.year_desc"
                           :value="item.graduated_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24"
                  v-if="text == '新增' && is_retail">
            <el-form-item label="APP"
                          prop="graduation_id">
              <el-select v-model="form.app_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in appList"
                           :key="item.app_id"
                           :label="item.app_name"
                           :value="item.app_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="名称"
                          prop="class_name">
              <el-input placeholder="请输入班级名称"
                        v-model="form.class_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="截止时间"
                          prop="end_time">
              <el-date-picker v-model="form.end_time"
                              @focus="$forbid"
                              style="width:100%"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="text == '修改'">
            <el-form-item label="状态"
                          prop="status">
              <el-radio-group v-model="form.status">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      leixing: '普通班级',
      dialogVisible: false,
      form: {},
      text: '',
      gradeList: [],
      rules: {
        class_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        graduation_id: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        end_time: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
      },
      jieList: [],
      appList: [],
      is_retail: 1
    }
  },
  computed: {},
  methods: {
    getAppList () {
      this.$http({
        url: 'api/v1/public/app_lst',
        method: 'get'
      }).then(res => {
        this.appList = res.data
      })
    },
    getList () {
      this.getJieList()
    },
    getJieList () {
      this.$http({
        url: '/api/v1/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.jieList = res.data
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      let url = this.text == '新增' ? '/api/v1/special/class_add' : '/api/v1/special/class_edit'

      let form = JSON.parse(JSON.stringify(this.form))
      form.graduation_id = [form.graduation_id]
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url,
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.warp {
  display: flex;
  margin-top: 20px;
  div {
    &:nth-child(1) {
      flex: 1;
      margin-left: 8px;
    }
    &:nth-child(2) {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>